@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin  for-tablet-under {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: rgba(0, 0, 0, .05);
}

@mixin segment-footer {
  background: #f9fafb;
  border-top: 1px solid rgba(34, 36, 38, .15);

  margin: 0 -1em -1em;
  padding: 1em;
}

$z-index-0: 0;
$z-index-1: 100;
$z-index-2: 200;
$z-index-3: 300;
$z-index-4: 400;
$z-index-5: 500;
$z-index-6: 600;
$z-index-7: 700;
$z-index-8: 800;
$z-index-9: 900;
$z-index-10: 1000;
