@import "../../config.scss";

$navigationWidth: 210px;
$menuHeight: 72px;
$contentPadding: 10px;

.head {
  background: #fff;
  // display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  display: flex;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 601;
  height: $menuHeight;
}

.topLeft {
  display: flex;
}

.burger {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;

  display: none;
  @include for-tablet-under {
    display: flex;
  }
}

.logo {
  margin-top: -10px;
  margin-left: -10px;
  margin-bottom: -10px;

  @include for-tablet-under {
    display: none;
  }
}

.search {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.user {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include for-tablet-under {
    display: none;
  }
}

.userName {
  margin: 0 10px;
  text-transform: uppercase;
  opacity: 0.6;
}

.menuWrapper {
  height: 100%;
  padding: 10px;
}

.menu {
  height: 100%;
}

.menuSection {
  border-bottom: solid thin #eee;
  // padding-top: .85714286em;
  
  &:first-child {
    padding-top: 0;
    margin-top: -0.85714286em * 2;
  }
}

.menuSectionTitle {
  padding: .85714286em 1.14285714em;
  background: #eee;
  font-weight: 600;
}

.userAvatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.navigationWrapper {
  display: flex;

  position: fixed;
  top: $menuHeight;
  left: 0;
  bottom: 0;

  z-index: 600;
}

.navigation {
  // background: white;
  display: block;
  height: 100%;
  @include for-tablet-under {
    display: none;
  }
}

.visible {
  display: block;
}
.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.content {
  position: relative;
  height: 100%;
  // padding: $contentPadding;
  // padding-top: $menuHeight + $contentPadding;
  // padding-left: $navigationWidth + $contentPadding + 10px;
  // @include for-tablet-under {
  //   padding-left: 10px;
  // }
  padding: $menuHeight + $contentPadding + 24 0 0 0;
  max-width: 1140px;
  margin: 0 auto;
}

.contentShadow {
  position: relative;
  &::before {
    position: absolute;
    z-index: 9;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    content: '';
    background: rgba(0, 0, 0, 0.7);
  }
}

.profilePopup {
  width: 250px;
}

.profileLink {
  color: black;
  padding: 10px;
  text-align: center;
  display: block;
  margin-bottom: 10px;
}


.logout {
  width: 100%;
}


.userActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
